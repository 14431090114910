<template>
  <div class="manage_top">
    <a-select
    v-model:value="commonTask"
    style="width: 120px; margin-right:10px"
    @change="taskChange"
    >
    <a-select-option value="1">常规任务</a-select-option>
    <a-select-option value="0">定点任务</a-select-option>
    </a-select>
    <a-input-search
    v-show="isCommon"
      v-model:value="value"
      placeholder="输入任务名称"
      style="width: 200px"
      @search="onSearch" 
      @keyup="getSearchData"
    />
    <a-button type="primary" @click="createModal" style="margin-left: 30px" v-show="!flag && isCommon">
      <template #icon><PlusOutlined /></template>新建任务
    </a-button>
     <a-button type="primary" class="delete" @click="getTaskData()"  shape="round">
      <ReloadOutlined />刷新</a-button>
     <a-modal
      :title="新建任务"
      v-model:visible="createVisible"
      @ok="create"
      @cancel="createHandleCancel"
      :confirm-loading="createConfirmLoading"
      cancelText="取消"
      okText="确认"
    >
      <ul class="input_list">
        <li>
          <span>任务名称：</span>
          <a-input v-model:value="task.name" placeholder="任务名称" />
        </li>

        <li>
          <span>任务描述：</span>
          <a-input v-model:value="task.message" placeholder="任务描述" />
        </li>
        <li>
          <span>选择时间：</span>
          <a-config-provider :locale="zhCN">
            <a-range-picker
              style="width: 300px"
              :show-time="{ format: 'HH:mm' }"
              format="YYYY-MM-DD HH:mm"
              :placeholder="['起始时间', '终止时间']"
              :value="createValue"
              @change="onCreateChange"
              @ok="onOk"
            />
          </a-config-provider>
        </li>
        <li>
          <span>任务区域：</span>
          <a-select
            v-model:value="task.sedimentation_type"
            style="width: 100px ;margin-right:20px"
            placeholder="请选择任务区域"
          >
            <a-select-option value="0">全区</a-select-option>
            <a-select-option value="2">海滩</a-select-option>
            <a-select-option value="1">海漂</a-select-option>
          </a-select>
          <a-select
            v-show="task.sedimentation_type === '2'"
            v-model:value="regionId0"
            style="width: 160px"
            placeholder="请选择任务区域"
          >
            <a-select-option value="1">区块1</a-select-option>
            <a-select-option value="2">区块2</a-select-option>
            <a-select-option value="3">区块3</a-select-option>
            <a-select-option value="4">区块4</a-select-option>
            <a-select-option value="5">区块5</a-select-option>
            <a-select-option value="6">区块6</a-select-option>
            <a-select-option value="7">区块7</a-select-option>
            <a-select-option value="8">区块8</a-select-option>
          </a-select>
          <a-select
            v-show="task.sedimentation_type === '1'"
            v-model:value="regionId1"
            style="width: 160px"
            placeholder="请选择任务区域"
          >
            <a-select-option value="1">区块A(1,2,3,4)</a-select-option>
            <a-select-option value="5">区块B(5,6)</a-select-option>
            <a-select-option value="7">区块C(7,8)</a-select-option>
          </a-select>
        </li>
      </ul>
    </a-modal>
    <a-modal
      title="编辑任务"
      v-model:visible="taskvisible"
      @ok="edittask"
      @cancel="handleCancel"
      :confirm-loading="confirmLoading"
      cancelText="取消"
      okText="确认"
    >
      <ul class="input_list">
        <li>
          <span>任务名称：</span>
          <a-input v-model:value="tasknode.name" placeholder="请编辑任务名称" />
        </li>
        <li>
          <span>任务描述：</span>
          <a-input
            v-model:value="tasknode.message"
            placeholder="请编辑任务描述"
          />
        </li>
        <li>
          <span>选择时间：</span>
          <a-config-provider :locale="zhCN">
            <a-range-picker
              style="width: 300px"
              :show-time="{ format: 'HH:mm' }"
              format="YYYY-MM-DD HH:mm"
              :placeholder="['起始时间', '终止时间']"
              :value="createValue"
              @change="onChange"
              @ok="onOk"
            />
          </a-config-provider>
        </li>
        <li>
          <span>任务区域：</span>
          <a-select
            v-model:value="task.sedimentation_type"
            style="width: 100px ;margin-right:20px"
            placeholder="请选择任务区域"
          >
            <a-select-option value="0">全区</a-select-option>
            <a-select-option value="2">海滩</a-select-option>
            <a-select-option value="1">海漂</a-select-option>
          </a-select>
          <a-select
            v-show="task.sedimentation_type === '2'"
            v-model:value="regionId0"
            style="width: 160px"
            placeholder="请选择任务区域"
          >
            <a-select-option value="1">区块1</a-select-option>
            <a-select-option value="2">区块2</a-select-option>
            <a-select-option value="3">区块3</a-select-option>
            <a-select-option value="4">区块4</a-select-option>
            <a-select-option value="5">区块5</a-select-option>
            <a-select-option value="6">区块6</a-select-option>
            <a-select-option value="7">区块7</a-select-option>
            <a-select-option value="8">区块8</a-select-option>
          </a-select>
          <a-select
            v-show="task.sedimentation_type === '1'"
            v-model:value="regionId1"
            style="width: 160px"
            placeholder="请选择任务区域"
          >
            <a-select-option value="1">区块A(1,2,3,4)</a-select-option>
            <a-select-option value="5">区块B(5,6)</a-select-option>
            <a-select-option value="7">区块C(7,8)</a-select-option>
          </a-select>
        </li>
      </ul>
    </a-modal>
  </div>


  <!-- 编辑定点任务的窗口 -->
    <a-modal
      title="编辑任务"
      v-model:visible="editTaskPointVisible"
      @ok="editTaskPoint"
      @cancel="editTaskPointCancel"
      :confirm-loading="editTaskPointLoading"
      cancelText="取消"
      okText="确认"
    >
      <ul class="input_list">
        <li>
          <span>任务名称：</span>
          <a-input :disabled="true" v-model:value="taskPointText.name" placeholder="请编辑任务名称" />
        </li>
        <li>
          <span>任务描述：</span>
          <a-input
            v-model:value="taskPointText.message"
            placeholder="请编辑任务描述"
          />
        </li>
        <li>
          <span>选择时间：</span>
          <a-config-provider :locale="zhCN">
            <a-range-picker
              style="width: 300px"
              format="YYYY-MM-DD"
              :placeholder="['起始时间', '终止时间']"
              :value="createValue"
              @change="onChange"
              @ok=";"
            />
          </a-config-provider>
        </li>
        <li>
          <span>任务区域：</span>
          <a-select
           :disabled="true"
            v-model:value="taskPointText.regionId"
            style="width: 160px"
            placeholder="请选择任务区域"
          >
            <a-select-option value="0">全区</a-select-option>
            <a-select-option value="1">区块1</a-select-option>
            <a-select-option value="2">区块2</a-select-option>
            <a-select-option value="3">区块3</a-select-option>
            <a-select-option value="4">区块4</a-select-option>
            <a-select-option value="5">区块5</a-select-option>
            <a-select-option value="6">区块6</a-select-option>
            <a-select-option value="7">区块7</a-select-option>
            <a-select-option value="8">区块8</a-select-option>
          </a-select>
        </li>
      </ul>
    </a-modal>

  <!-- 展示定点任务的详情框 -->
  <a-modal
    title="任务详情"
    width="1000px"
    :visible="photoVisible"
    @cancel="photoCancel"
  >
  <div style="position:relative; width:100%;height:400px">
    <span style="display:block;">待清理图片：</span>
    <img  style="position:absolute;width:600px; left:20%;" :src="waitingCleanPhoto" alt="">
  </div>
  <div>清理情况：</div>
  <div style=" width: 100%; display: flex; flex-wrap: wrap; ">
        <div v-for="photo in taskPointText.photoList" :key="photo.photoUrl" style=" position: relative;width: 30%; height:100px; margin: 10px">
          <img v-bind:src="photo.photoUrl" style="  position: absolute;top:50%;left: 50%;transform: translate(-50%, -50%); height:100%; cursor:pointer" @click="showPhoto(photo.photoUrl)" >
        </div>
    </div>
  <template #footer>
      <a-button @click="photoCancel">取消</a-button>
    </template>
  </a-modal>
  <!-- 展示大图的模态框 -->
  <a-modal v-model:visible="showPhotoVisible" title="图片详情" :footer="null" width="800" :centered="true">
      <img :src="showPhotoUrl" height="500" >
  </a-modal>
  <!-- 员工完成任务时上传图片的模态框 -->
  <a-modal
    title="完成任务"
    v-model:visible="finishVisible"
    @cancel="finishCancel"
    @ok="finishPointTask"
    cancelText="取消"
    okText="确认"
  >
  <div>上传图片垃圾清理照片：</div>
          <div style="width: 100%; height:100%;">
          <a-upload
              list-type="picture-card"
              v-model:fileList="fileList"
              @preview="handlePreview"
              :beforeUpload="beforeUpload"
              :multiple="true"
              :remove="handleRemove"
              @change="handleChange"
          >
            <div v-if="fileList.length < 9">
              <PlusOutlined/>
              <div class="ant-upload-text">添加图片</div>
            </div>
          </a-upload>
          <!-- 放大预览窗口 -->
          <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancelImg">
            <img alt="example" style="width: 100%" :src="previewImage"/>
          </a-modal>
        </div>
  </a-modal>
  <!-- 加载图片时的进度条 -->
    <a-modal 
    :visible="lodaingVisible"
    :footer="null"
    :closable="false">
      <a-spin >
      <a-alert a-alert
        message="正在上传"
        description="请不要关闭窗口"
      ></a-alert>
      </a-spin>
    </a-modal>


  <a-table
    v-show="isCommon"
    :columns="columns"
    :data-source="data"
    :loading="loading"
    :pagination="pagination"
    :row-key="(record) => record.id"
    @change="getCTaskData"
    bordered
    class="ant-table-striped"
    :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
  >
    <template #name="{ text }">
      <a>{{ text }}</a>
    </template>
    <template #mytimeout="{ text }">
      <span v-if="text == 0">未完成</span>
      <span v-if="text == 1">完成</span>
      <span v-if="text == 2">过期</span>
    </template>

    <template #action="{ text }">
      <a-button type="primary" @click="doEdit(text)" 
      :disabled="editControl(text)" 
      v-show="text.success === 0"
      :size="size" shape="round"
        >编辑任务</a-button
      >
      <a-button type="primary" @click="doEdit(text)" 
      v-show="text.success === 2 && flag " 
      :size="size" shape="round"
        >重派任务</a-button
      >
      <a-button
        type="primary"
        @click="finish(text)"
        class="finish"
        v-show="text.success == 0 || !flag "
        :size="size"
        shape="round"
        :disabled="text.success == 1 || text.success == 2"
        >完成任务</a-button
      >
      <a-button type="danger" class="delete" v-show="flag" @click="deleteTask(text)"  :size="size" shape="round"
        >删除</a-button
      >
      <a-button class="distribution" v-show="text.success != 1 &&flag &&text.success!=2" @click="remindUser(text)" :size="size" shape="round"
        >提醒用户</a-button
      >
    </template>
    
  </a-table>

  <a-table
  v-show="!isCommon"
    :columns="columns1"
    :data-source="data1"
    :loading="loading1"
    :pagination="pagination1"
    :row-key="(record) => record.id"
    @change="getCTaskData1"
    bordered
    :customRow="rowClick"
    class="ant-table-striped"
    :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
  >
    <template #name="{ text }">
      <a>{{ text }}</a>
    </template>
    <template #mytimeout="{ text }">
      <span v-if="text == 0">未完成</span>
      <span v-if="text == 1">完成</span>
      <span v-if="text == 2">过期</span>
    </template>

    <template #action="{ text }">
      <a-button type="dashed" shape="round" style="margin-right:10px;" :size="size" @click="guideLocation(text)">
        <EnvironmentOutlined />
        位置
      </a-button>
      <a-button type="primary" @click="doEditTaskPoint(text)" 
      :disabled="editControl(text)" 
      v-show="text.success === 0"
      :size="size" shape="round"
        >编辑任务</a-button
      >
      <a-button type="primary" @click="doEditTaskPoint(text)" 
      v-show="text.success === 2 && flag " 
      :size="size" shape="round"
        >重派任务</a-button
      >
      <a-button
        type="primary"
        @click="finishPoint(text)"
        class="finish"
        v-show="text.success === 0 && !flag "
        :size="size"
        shape="round"
        :disabled="text.success == 1 || text.success == 2"
        >完成任务</a-button
      >
      <a-button type="danger" class="delete" v-show="flag" @click="deleteTask(text)"  :size="size" shape="round"
        >删除</a-button
      >
      <a-button class="distribution" v-show="text.success != 1 &&flag &&text.success!=2" @click="remindUserPoint(text)" :size="size" shape="round"
        >提醒用户</a-button
      >
    </template>
  </a-table>

</template>

<script >
import { createVNode, defineComponent } from "vue";
import {  Modal } from 'ant-design-vue';
import { ExclamationCircleOutlined ,PlusOutlined,ReloadOutlined,EnvironmentOutlined} from '@ant-design/icons-vue';
import { message } from "ant-design-vue";
import enUS from "ant-design-vue/es/locale/en_US";
import zhCN from "ant-design-vue/es/locale/zh_CN";
import moment from "moment";
import "moment/dist/locale/zh-cn";
import OSS from "ali-oss";

moment.locale("zn");

const client = new OSS({
  region: "oss-cn-beijing",
  accessKeyId: "LTAI4G6p1JZZMbXL1KcTJFfB",
  accessKeySecret: "5Og0iIKOSnUss67L2hEjXkCpTUdeCh",
  bucket: "rubbish-photo",
});

const columns = [
  {
    title: "任务名称",
    dataIndex: "name",
    key: "name",
    // slots: { customRender: "name" },
    width: '11%',
    align: "center",
  },
  {
    title: "任务描述",
    dataIndex: "message",
    key: "message",
    width: '18%',
    align: "center",
  },
  {
    title: "派遣人",
    dataIndex: "senderName",
    key: "senderName",
    align: "center",
    width: '10%',
  },
  {
    title: "被派遣人",
    dataIndex: "recipientName",
    key: "recipientName",
    align: "center",
    width: '12%',
  },
  {
    title: "区域",
    dataIndex: "regionId",
    key: "regionId",
    width: '7%',
    // slots: { customRender: "myregionId" }, //customRender是固定写法，这个myduty是自己的模板
    align: "center",
  },
  {
    title: "起始时间",
    dataIndex: "startTime",
    key: "startTime",
    align: "center",
    width: '9%',
    // slots: { customRender: "mystartTime" },
  },
  {
    title: "截至日期",
    dataIndex: "endTime",
    key: "endTime",
    align: "center",
    width: '9%',
  },
  {
    title: "状态",
    dataIndex: "success",
    key: "success",
    align: "center",
    slots: { customRender: "mytimeout" },
    width: '7%',
  },

  {
    title: "操作",
    slots: { customRender: "action" },
    key: "action",
    align: "center",
    width: '35%',
    ellipsis: true,
  },
];
const columns1 = [
  {
    title: "任务描述",
    dataIndex: "message",
    key: "message",
    width: '17%',
    align: "center",
  },
  {
    title: "派遣人",
    dataIndex: "senderName",
    key: "senderName",
    align: "center",
    width: '10%',
  },
  {
    title: "被派遣人",
    dataIndex: "recipientName",
    key: "recipientName",
    align: "center",
    width: '10%',
  },
  {
    title: "区域",
    dataIndex: "regionId",
    key: "regionId",
    width: '6%',
    // slots: { customRender: "myregionId" }, //customRender是固定写法，这个myduty是自己的模板
    align: "center",
  },
  {
    title: "起始时间",
    dataIndex: "startTime",
    key: "startTime",
    align: "center",
    width: '9%',
    // slots: { customRender: "mystartTime" },
  },
  {
    title: "截至日期",
    dataIndex: "endTime",
    key: "endTime",
    align: "center",
    width: '9%',
  },
  {
    title: "状态",
    dataIndex: "success",
    key: "success",
    align: "center",
    slots: { customRender: "mytimeout" },
    width: '7%',
  },

  {
    title: "操作",
    slots: { customRender: "action" },
    key: "action",
    align: "center",
    width: '35%',
    ellipsis: true,
  },
];
const data = [];
const data1 = [];

export default defineComponent({
  name: "Taskmanage",
  components: {
    PlusOutlined,
    ReloadOutlined,
    EnvironmentOutlined,
  },
  data() {
    return {
      // 常规任务与定点任务控制相关
      //控制管理员看不到定点任务的完成任务按钮
      isAdmin: this.$storage.get('useriinfo').role === 0,
      //两个表格的切换显示
      isCommon: true,
      // "1"是常规任务 "0"是定点任务      
      commonTask:'1',
      columns1:columns1,
      data1:data1,
      pagination1:{
        pageSize:7,
        total:0,
        current:1.
      },
      loading1:false,
      //控制图片弹窗的开关
      photoVisible: false,
      showPhotoVisible:false,
      showPhotoUrl: '',
      waitingCleanPhoto: '',
      //-------完成定点任务相关
      //控制完成定点任务的弹框
      finishVisible: false,
      fileList: [],
      nameList: [],
      realList: [],
      //预览相关
      previewVisible: false,
      previewImage: "",
      lodaingVisible: false,
      photoUrls: [],
      //存方当前完成任务选中的任务的信息
      taskPointText: {},
      //缓冲条控制
      countUp: 0,
      countDone: 0,
      //编辑任务的弹框控制
      editTaskPointVisible: false,
      //编辑定点任务的时间控制
      isUp: true,
      

      regionId0: '1',
      regionId1: '1',
      text: '', // 用来控制不同用户登录时表单中 派遣人/执行人员的不同
      flag: this.$storage.get("userinfo").data.role === 0,  // 这里是用来根据登录用户权限的不同控制按钮的出现
      enUS: enUS,
      zhCN: zhCN,
      taskvisible: false,
      createVisible: false,
      confirmLoading: false,
      createConfirmLoading: false,
      timer: 0,
      value: "",
      data: data,
      columns: columns,
      loading: false,
      size: "small", // 控制按钮的大小
      task: {
        name: "",
        region: undefined,
        message: "",
        startTime: "",
        endTime: "",
        senderId: "",
        recipientId: "",
        success: 0,
        sedimentation_type: '0',
      }, // 用于给自己分配任务
      pagination: {
        pageSize: 7, //一页是8条数据
        total: 10,
        current: 1,
      },
      tasknode: {
        name: "",
        message: "",
        region: undefined,
        startTime: "",
        endTime: "",
        senderId: "",
        recipientId: "",
        success: "",
        id: "",
      },
      createValue: [],
    };
  },
  methods: {
    //常规任务与定点任务切换相关
    taskChange(value) {
      this.isCommon = !this.isCommon;
      this.getTaskData()
      console.log(value);
    },
    //列表行点击事件
    rowClick(record, index) {
			return {
				style: {
					'background-color':
						this.selectedRow && this.selectedRow == record.id
							? 'RGB(0,240,0,50%)'
							: '#FFF' //行背景颜色的改变
				},
				onDblclick: () => {
          this.taskPointText = JSON.parse(JSON.stringify(record));
          this.waitingCleanPhoto = record.photo.photoUrl;
          console.log(this.taskPointText);
          console.log(index);
          //打开图片弹窗
          this.photoVisible = true;
          this.$axios({
            url:"http://39.100.158.75:8080/photo/getOnePhotoById",
            method: "GET",
            headers:{
              token: this.$storage.get("userinfo").data.token,
            },
            params:{
              photoId:record.photoId,
            }
          }).then((res) => {
            this.waitingCleanPhoto = res.data.data.photoUrl;
            console.log(this.waitingCleanPhoto);
          })
        }
      }
    },
    // 分页
    getCTaskData1(event) {
      this.pagination1.current = event.current;
      this.getTaskData();
    },
    //关闭任务详情展示框
    photoCancel() {
      this.photoVisible = false;
    },
    showPhoto(showPhotoUrl) {
      this.showPhotoVisible = true;
      this.showPhotoUrl = showPhotoUrl;
    },
    //打开完成定点任务的弹框
    finishPoint(text) {
      this.taskPointText = text;
      this.taskPointText.sedimentation_type =  this.taskPointText.sedimentation_type  === 0 ? '0' :(this.taskPointText.sedimentation_type === 1 ? '1': (this.taskPointText.sedimentation_type === 2 ? '2': ''))
      if(this.taskPointText.sedimentation_type === 1 ) this.taskPointText.regionId =  text.regionId  === 'A' ? '1' :(text.regionId === 'B' ? '5': (text.regionId === 'C' ? '7': ''))
      this.finishVisible = true;
    },
    //真正的完成定点任务
    async finishPointTask() {
      console.log(this.taskPointText);
      this.createValue = [this.taskPointText.startTime,this.taskPointText.endTime];
      console.log(this.photoUrls);
        let api = "http://39.100.158.75:8080//pointMission/updateOneMission";
          await this.$axios({
            url: api,
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              token: this.$storage.get("userinfo").data.token,
            },
            data: {
              id: this.taskPointText.id,
              startTime: this.taskPointText.startTime,
              endTime: this.taskPointText.endTime,
              message: this.taskPointText.message,
              success: '1',
              photoListUrlInput: this.photoUrls,
            },
          })
            .then((response ) => {
              if (response.data.success) {
                message.success('任务成功!');
                this.taskPointText = {};
                this.createValue = [];
              } else {
                 this.taskPointText = {};
                 this.createValue = [];
                message.error(response.data.message);
              }
            })
            .catch((error) => {
              console.log(error);
            });
          this.getTaskData();
          this.finishVisible = false;

    },
    //关闭完成定点任务的模态框
    finishCancel() {
      this.finishVisible = false;
      for(const name of this.nameList) {
        client.delete(name);
      } 
      this.fileList = [];
    },
    //导航
    guideLocation(text) {
      console.log(text);
      this.$router.push({ path: '/mapFindPoint', query: {id: text.photoId } }) ;
    },
    //打开编辑任务弹框
    doEditTaskPoint(text) {
      this.editTaskPointVisible = true;
      this.taskPointText = JSON.parse(JSON.stringify(text));
      this.createValue = [this.taskPointText.startTime,this.taskPointText.endTime];
      this.taskPointText.sedimentation_type =  this.taskPointText.sedimentation_type  === 0 ? '0' :(this.taskPointText.sedimentation_type === 1 ? '1': (this.taskPointText.sedimentation_type === 2 ? '2': ''))
      if(this.taskPointText.sedimentation_type === '1' ) this.taskPointText.regionId =  text.regionId  === 'A' ? '1' :(text.regionId === 'B' ? '5': (text.regionId === 'C' ? '7': ''))
      console.log(this.taskPointText);
      console.log(text);
    },
    //编辑定点任务
    editTaskPoint() {
      
        let api = "http://39.100.158.75:8080//pointMission/updateOneMission";
          this.$axios({
            url: api,
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              token: this.$storage.get("userinfo").data.token,
            },
            data: {
              id: this.taskPointText.id,
              startTime: this.taskPointText.startTime,
              endTime: this.taskPointText.endTime,
              message: this.taskPointText.message,
              success: this.taskPointText.success,
            },
          })
            .then((response ) => {
              if (response.data.success) {
                message.success('编辑任务成功');
                this.taskPointText = {};
                this.createValue = [];
              } else {
                 this.taskPointText = {};
                 this.createValue = [];
                message.error(response.data.message);
              }
            })
            .catch((error) => {
              console.log(error);
            });
          this.getTaskData();
          this.editTaskPointVisible = false;
    },
    //关闭编辑任务的弹框
    editTaskPointCancel() {
      this.editTaskPointVisible = false;
      this.taskPointText = {};
      this.createValue = [];
    },
    //提醒用户定点任务
    remindUserPoint(data) {
        console.log(data);
        const that = this;
        let missionId = '';
        if(this.isCommon) {
          missionId = data.id;
        } else {
          missionId = '0';
        }
        Modal.confirm({
          title: '你确定要提醒该用户吗？',
          icon: createVNode(ExclamationCircleOutlined),
          content: createVNode('div', {style: 'color: red;'}, '本次操作将会在消息通告中增加一条消息！'),
          okText: '确定',
          cancelText: '取消',
          onOk() {
              let url = 'http://39.100.158.75:8080/message/insertOneMessage';
        that.$axios({
            url: url,
            method: 'POST',
            headers: {
                token: that.$storage.get("userinfo").data.token,
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
                message: data.name,
                time: data.endTime,
                recipientId: data.recipientId,
                missionId: missionId,
                senderId:  that.$storage.get("userinfo").data.userId
            })
        }).then((res) => {
            console.log(res);
            if (res.data.success == true) {
                message.info(res.data.message);
            } else {
                message.info(res.data.message);
            }
        })
          },
          onCancel() {}
        });
    },
    //图片墙的预览删除上传逻辑
     // 上传前
    beforeUpload(file) {
      const thePicture =
          file.type === "image/png" ||
          file.type === "image/jpeg" ||
          file.type === "image/jpg";
      if (this.fileList.length > 8) {
        return false
      } else if (!thePicture) {
        message.error(file.name + '文件格式错误!请重新上传正确格式文件')
      }
      this.exeBase(file)
      return false
    },
    // 上传时
    handleChange(file) {
      console.log(file);
      let name = file.file.name; //获取文件名字
      let file2 = file.file;
      let obj = new Date().getTime(); //按时间命名，文件名字
      let storeAs =
          obj + "." + name; //file文件总名字
      this.nameList.push(storeAs)
      //上传第一张图片之前开启等待框
      if(this.isUp) {
        this.lodaingVisible = true;
      //上传oss成功后增加一个countup的值
      this.countUp++;
      console.log(this.countUp);
      client.put(storeAs, file2).then((results) => {
        console.log(results.url);
        this.photoUrls.push(results.url)
        //上传成功后增加一个成功的图片
        this.countDone++
        console.log(this.countDone);
        if(this.countDone === this.countUp) {
          this.lodaingVisible = false;
          this.countDone = 0;
          this.countUp = 0;
        }
      })
      } 
    },
    // 在这里异步等待base64转码的结果
    async exeBase(file) {
      await this.getBase64(file, (imageUrl) => {
        if (this.fileList.length < 9) {
          file.url = imageUrl
          this.realList.push(file);
          this.loading = false
        }
      })
    },
    // 获取base64的编码
    getBase64(file, callback) {
      return new Promise((resolve) => {
        let reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
          callback(reader.result)
          resolve('成功')
        }
      })
    },
    getBase(file) {
      this.base64Url = '';
      let that = this;
      let imgFile = new FileReader();
      imgFile.readAsDataURL(file);
      imgFile.onload = function () {
        console.log(this.result);
        that.base64Url = this.result.toString().replace('data:image/jpeg;base64,', '');
      }
    },
    handlePreview(file) {//点击查看大图
      let original = file.originFileObj;
      this.previewImage = URL.createObjectURL(original);
      this.previewVisible = true
    },
    // 这里用来移除图片中的某一项
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      // 删除时不打开加载框
      this.isUp = false;
      // 删除成功后打开加载框控制变量
      client.delete(this.nameList[index]).then(() => {this.isUp = true});
      const newPhotoList = this.photoUrls.slice()
      const newNameList = this.nameList.slice()
      const newFileList = this.fileList.slice()
      const newRealList = this.realList.slice()
      newPhotoList.splice(index, 1)
      newNameList.splice(index, 1)
      newFileList.splice(index, 1)
      newRealList.splice(index, 1)
      this.photoUrls = newPhotoList
      this.nameList = newNameList
      this.fileList = newFileList
      this.realList = newRealList
    },
    handleCancelImg() {//点击关闭图片预览
      this.previewVisible = false;
    },


    //编辑按钮的控制逻辑
    editControl(text) {
      return  !this.flag && text.senderId != this.$storage.get('userinfo').data.userId;
    },
    // 普通用户为自己新建任务
    // 打开新建任务的弹框
    createModal() {
      this.task.senderId = this.$storage.get("userinfo").data.userId;
      this.task.recipientId = this.$storage.get("userinfo").data.userId;
      this.createVisible = true;
    },
    // 新建任务的时间范围确定按钮
    onCreateChange(value, dateString) {
      this.createValue = value;
      this.task.startTime = dateString[0];
      this.task.endTime = dateString[1];
    },
    // 新建任务
    create() {
      if (
        this.$storage.get("userinfo").data.token == null ||
        this.$storage.get("userinfo").data.token == ""
      ) {
        this.$router.push({
          path: "/login",
        });
      } else {
        if (this.task.name == "" || this.task.name == null) {
          message.error("请输入任务名称");
        } else if (this.task.message == "" || this.task.message == null) {
          message.error("请输入任务描述");
        } else if (this.task.startTime == "" || this.task.endTime == "") {
          message.error("请选择时间");
        }  else {
          // console.log(this.editData);
          let regionId = '';
          if(this.task.sedimentation_type === '1') {
            regionId = this.regionId1;
          } else if(this.task.sedimentation_type === '2') {
            regionId = this.regionId0
          } else {
            regionId = '0'
          }
          this.createConfirmLoading = true;
          let api = "http://39.100.158.75:8080/mission/insertOneMission";
          this.$axios({
            url: api,
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              token: this.$storage.get("userinfo").data.token,
            },
            params: {
              senderId: this.task.senderId,
              recipientId: this.task.recipientId,
              startTime: this.task.startTime,
              endTime: this.task.endTime,
              regionId: regionId,
              message: this.task.message,
              success: this.task.success,
              name: this.task.name,
              sedimentation_type: this.task.sedimentation_type,
            },
          })
            .then((response ) => {
              if (response.data.success) {
                message.success("分配任务成功");
                this.task.name = "";
                this.task.region = undefined;
                this.task.startTime = "";
                this.task.endTime = "";
                this.task.message = "";
                this.createValue = [];
                this.task.sedimentation_type = '0';
                this.regionId0 = '1';
                this.regionId1 = '1';
              } else {
                message.error(response.data.message);
              }
            })
            .catch((error) => {
              console.log(error);
            });
          setTimeout(() => {
            this.createVisible = false;
            this.createConfirmLoading = false;
          }, 2000);
           this.getTaskData();
        }
      }
    },
    // 清空并关闭新建任务的对话框
    createHandleCancel() {
      this.task.name = "";
      this.task.region = undefined;
      this.task.startTime = "";
      this.task.endTime = "";
      this.task.message = "";
      this.createValue = [];
      this.task.sedimentation_type = '0';
      this.regionId0 = '1';
      this.regionId1 = '1';
    },

    // 编辑任务
    // 点击编辑按钮后 打开编辑任务按钮的弹框
    doEdit(data) {
      console.log(data);
      console.log(data.action)
      this.taskvisible = true;
      this.tasknode.name = data.name;
      this.tasknode.message = data.message;
      this.tasknode.id = data.id;
      this.tasknode.recipientId = data.recipientId;
      this.tasknode.senderId = data.senderId;
      this.tasknode.success = data.success;
    },
    // 编辑任务的时间范围确定按钮
    onChange(value, dateString) {
      this.createValue = value;
      //常规任务的时间编辑
      if(this.isCommon) {
      this.tasknode.startTime = dateString[0];
      this.tasknode.endTime = dateString[1];
      } else {
      this.taskPointText.startTime = dateString[0];
      this.taskPointText.endTime = dateString[1];
      }
    },
    // 编辑任务
    edittask() {
      if (
        this.$storage.get("userinfo").data.token == null ||
        this.$storage.get("userinfo").data.token == ""
      ) {
        this.$router.push({
          path: "/login",
        });
      } else {
        if (this.tasknode.name == "" || this.tasknode.name == null) {
          message.error("请输入姓名");
        } else if (
          this.tasknode.message == "" ||
          this.tasknode.message == null
        ) {
          message.error("请输入描述");
        } else if (
          this.tasknode.startTime == "" ||
          this.tasknode.endTime == ""
        ) {
          message.error("请选择时间");
        }  else {
          // console.log(this.editData);
          let regionId = '';
          if(this.task.sedimentation_type === '1') {
            regionId = this.regionId1;
          } else if(this.task.sedimentation_type === '2') {
            regionId = this.regionId0
          } else {
            regionId = '0'
          }
          this.confirmLoading = true;
          let api = "http://39.100.158.75:8080/mission/updateOneMission";
          this.$axios({
            url: api,
            method: "POST",
            headers: {
              token: this.$storage.get("userinfo").data.token,
            },
            params: {
              id: this.tasknode.id,
              senderId: this.tasknode.senderId,
              recipientId: this.tasknode.recipientId,
              startTime: this.tasknode.startTime,
              endTime: this.tasknode.endTime,
              regionId: regionId,
              message: this.tasknode.message,
              success: this.tasknode.success,
              name: this.tasknode.name,
              sedimentation_type: this.task.sedimentation_type,
            },
          })
            .then((response) => {
              if (response.data.success) {
                message.success(response.data.message);
                this.tasknode.name = "";
                this.tasknode.region = undefined;
                this.tasknode.startTime = "";
                this.tasknode.endTime = "";
                this.tasknode.message = "";
                this.createValue = [];
                this.task.sedimentation_type = '0';
                this.regionId0 = '1';
                this.regionId1 = '1';
              } else {
                message.error(response.data.message);
              }

              // console.log(response);
            })
            .catch((error) => {
              console.log(error);
            });
          setTimeout(() => {
            this.getTaskData();
            this.taskvisible = false;
            this.confirmLoading = false;
          }, 2000);
        }
      }
    },
    // 清空并关闭编辑任务的对话框
    handleCancel() {
      this.tasknode.name = "";
      this.tasknode.message = "";
      this.tasknode.region = undefined;
      this.tasknode.startTime = "";
      this.tasknode.endTime = "";
      this.createValue = [];
      this.task.sedimentation_type = '0';
      this.regionId0 = '1';
      this.regionId1 = '1';
    },

    onOk() {}, //没用

    // 完成任务
    finish(data) {
      console.log(data);
        (this.tasknode.id = data.id),
        (this.tasknode.senderId = data.senderId),
        (this.tasknode.recipientId = data.recipientId),
        (this.tasknode.startTime = data.startTime),
        (this.tasknode.endTime = data.endTime),
        (this.tasknode.region = data.regionId),
        (this.tasknode.message = data.message),
        (this.tasknode.success = "1"),
        (this.tasknode.name = data.name),
        (this.confirmLoading = true);
        if(data.sedimentation_type === 0) {
          this.tasknode.sedimentation_type = 0;
          this.tasknode.region = '0';
        } else if(data.sedimentation_type === 1) {
          this.tasknode.sedimentation_type = 1;
          this.tasknode.region =  data.regionId  === 'A' ? '1' :(data.regionId === 'B' ? '5': (data.regionId === 'C' ? '7': ''))
        } else {
          this.tasknode.sedimentation_type = 2;
          this.tasknode.region = data.regionId;
        }
        console.log(this.tasknode);
        const that = this;
        Modal.confirm({
          title: '请确认该任务已完成！',
          icon: createVNode(ExclamationCircleOutlined),
          content: createVNode('div', {style: 'color: red;'}, ''),
          okText: '确定',
          cancelText: '取消',
          onOk() {
              let api = "http://39.100.158.75:8080/mission/updateOneMission";
      that.$axios({
        url: api,
        method: "POST",
        headers: {
          token: that.$storage.get("userinfo").data.token,
        },
        params: {
          id: that.tasknode.id,
          senderId: that.tasknode.senderId,
          recipientId: that.tasknode.recipientId,
          startTime: that.tasknode.startTime,
          endTime: that.tasknode.endTime,
          regionId: that.tasknode.region,
          message: that.tasknode.message,
          success: that.tasknode.success,
          name: that.tasknode.name,
          sedimentation_type: that.tasknode.sedimentation_type,
        },
      })
        .then((response) => {
          if (response.data.success) {
            message.success("完成任务");
          } else {
            message.error(response.data.message);
          }

          // console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
      setTimeout(() => {
        that.getTaskData();
        that.taskvisible = false;
        that.confirmLoading = false;
      }, 2000);
          },
          onCancel() {}
        });
      
    },
    // 删除任务
    deleteTask(data) {
      const that = this;
      Modal.confirm({
          title: '你确定要删除此项任务吗？',
          icon: createVNode(ExclamationCircleOutlined),
          content: createVNode('div', {style: 'color: red;'}, '本次操作将会删除这个任务！'),
          okText: '确定',
          cancelText: '取消',
          onOk() {       
            if(that.isCommon) {
               let api = "http://39.100.158.75:8080/mission/deleteOneMission";
                that.$axios({
                  url: api,
                  method: "POST",
                  headers: {
                    token: that.$storage.get("userinfo").data.token,
                  },
                  params: {
                    missionId: data.id,
                  },
                })
                  .then((response) => {
                    if (response.data.success) {
                      message.success(response.data.message);
                    } else {
                      message.error(response.data.message);
                    }
                    let pagNum = that.pagination.total / that.pagination.pageSize;
                      if((that.pagination.total-1) % that.pagination.pageSize === 0 && (that.pagination.current) > pagNum){                     
                        that.pagination.current =
                        that.pagination.current - 1 > 0 ? that.pagination.current - 1 : 1;
                    }
                    that.getTaskData();
                  })
                  .catch((error) => {
                    console.log(error);
                  });
            } else {
              let api = "http://39.100.158.75:8080//pointMission/deleteOneMission";
                that.$axios({
                  url: api,
                  method: "POST",
                  headers: {
                    token: that.$storage.get("userinfo").data.token,
                  },
                  params: {
                    missionId: data.id,
                  },
                })
                  .then((response) => {
                    if (response.data.success) {
                      message.success(response.data.message);
                    } else {
                      message.error(response.data.message);
                    }
                    let pagNum = that.pagination1.total / that.pagination1.pageSize;
                      if((that.pagination1.total-1) % that.pagination1.pageSize === 0 && (that.pagination1.current) > pagNum){                     
                        that.pagination1.current =
                        that.pagination1.current - 1 > 0 ? that.pagination1.current - 1 : 1;
                    }
                    that.getTaskData();
                  })
                  .catch((error) => {
                    console.log(error);
                  });
            }
          },
          onCancel() {}
      });
    },

    // 查询与搜索
    // 输入完成后自动对内容进行搜索
    getSearchData() {
      if (this.value != "") {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.pagination.current = 1
          this.getTaskData();
        }, 600);
      }
    },
    // 输入完成后手动点击开启搜索
    onSearch() {
      this.pagination.current = 1
      this.getTaskData();
    },
    // 分页查询任务
    getCTaskData(event) {
      this.pagination.current = event.current;
      this.getTaskData();
    },
    // 真正的查询任务的接口
    getTaskData() {
      if(this.isCommon) {
        this.loading = true;
      let api = "http://39.100.158.75:8080/mission/getMissionsByKeyWord";
      this.$axios({
        url: api,
        method: "GET",
        headers: {
          token: this.$storage.get("userinfo").data.token,
        },
        params: {
          page: this.pagination.current,
          limit: this.pagination.pageSize,
          keyWord: this.value,
        },
      })
        .then((response) => {
          console.log(response);
          if (response.data.data == null) {
            message.info(response.data.message);
            this.data = [];
            this.pagination.total = 0;
            this.loading = false;
          } else {
            this.data = response.data.data.list;
            this.pagination.total = response.data.data.totalPageCount;
            this.loading = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
      } else {
        this.loading1 = true;
      let api = "http://39.100.158.75:8080//pointMission/getMissionsByKeyWord";
      this.$axios({
        url: api,
        method: "GET",
        headers: {
          token: this.$storage.get("userinfo").data.token,
        },
        params: {
          page: this.pagination1.current,
          limit: this.pagination1.pageSize,
        },
      })
        .then((response) => {
          console.log(response);
          if (response.data.data == null) {
            message.info(response.data.message);
            this.data1 = [];
            this.pagination1.total = 0;
            this.loading1 = false;
          } else {
            this.data1 = response.data.data.list;
            this.pagination1.total = response.data.data.totalPageCount;
            this.loading1 = false;
            for(let i = 0;i<response.data.data.list.length;i++) {
              this.data1[i].regionId = '区块'+this.data1[i].photo.regionId+'';
            }
            console.log(this.data1);
          }
        })
        .catch((error) => {
          console.log(error);
        });
      }
    },

    //提醒用户
    remindUser(data) {
        console.log(data);
        const that = this;
        Modal.confirm({
          title: '你确定要提醒该用户吗？',
          icon: createVNode(ExclamationCircleOutlined),
          content: createVNode('div', {style: 'color: red;'}, '本次操作将会在消息通告中增加一条消息！'),
          okText: '确定',
          cancelText: '取消',
          onOk() {
              let url = 'http://39.100.158.75:8080/message/insertOneMessage';
        that.$axios({
            url: url,
            method: 'POST',
            headers: {
                token: that.$storage.get("userinfo").data.token,
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
                message: data.name,
                time: data.endTime,
                recipientId: data.recipientId,
                missionId: data.id,
                senderId:  that.$storage.get("userinfo").data.userId
            })
        }).then((res) => {
            console.log(res);
            if (res.data.success == true) {
                message.info(res.data.message);
            } else {
                message.info(res.data.message);
            }
        })
          },
          onCancel() {}
        });
    },
  },
  mounted() {
    if(this.$storage.get('userinfo').data.role === 0) {
      this.columns = this.columns.filter((item) => item.dataIndex !== 'senderName')
      this.columns1 = this.columns1.filter((item) => item.dataIndex !== 'senderName')
    } else {
      this.columns = this.columns.filter((item) => item.dataIndex !== 'recipientName')
      this.columns1 = this.columns1.filter((item) => item.dataIndex !== 'recipientName')
    }
    this.getTaskData();
  },
});
</script>

<style lang="scss" scoped>
// 修改表格的内边距
:deep().ant-table-tbody > tr > td {
  padding: 20px 10px;
}
//修改任务管理中的文字样式为居中
// :deep() .ant-table-row-cell-break-word:nth-child(2) {
//   text-align: left  !important;
// }
.ant-table-striped :deep(.table-striped) {
  background-color: #fafafa;
} // 设置斑马纹的样式
.manage_top {
  padding: 10px 5px;
//   background: #fffced;
}
.delete {
  margin-left: 10px;
}
.finish {
  margin-left: 10px;
}
.distribution {
  margin-left: 10px;
}
ul {
  list-style: none;
}
.input_list {
  padding: 0px;
  li {
    line-height: 44px;
    span {
      display: inline-block;
      width: 100px;
    }
    input {
      width: 300px;
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
/* chrome */

input[type="number"] {
  -moz-appearance: textfield; /* firefox */
}
</style>